<template>
  <InformationPage name="Проекты ВКонтакте">
        Скоро напишу о VK Fresh Code и ещё одном проекте&hellip;
  </InformationPage>
</template>

<script>
import InformationPage from "@/components/InformationPage";

export default {
  components: {
    InformationPage
  }
}
</script>
