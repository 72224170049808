<template>
  <p class="my-1"><img :src="medal ? medals[medal][0] : medals['0'][0]" :alt="medal ? medals[medal][1] : medals['0'][1]" class="h-5 mr-2 inline"> <slot></slot> <sup>{{ year }}</sup></p>
</template>

<script>

export default {
  props: ['year', 'medal'],
  computed: {
    medals: () => ({
      "1": ["https://upload.wikimedia.org/wikipedia/commons/4/47/Gold_medal_icon.svg", "Золотая медаль"],
      "2": ["https://upload.wikimedia.org/wikipedia/commons/2/2e/Silver_medal_icon.svg", "Серебряная медаль"],
      "3": ["https://upload.wikimedia.org/wikipedia/commons/8/89/Bronze_medal_icon.svg", "Бронзовая медаль"],
      "0": ["https://upload.wikimedia.org/wikipedia/commons/a/ae/Green_medal_icon_blank.svg", "Участие"]
    })
  }
}
</script>
