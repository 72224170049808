import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import './styles.scss'
import Home from "@/components/Home";
import Programming from "@/components/Programming";
import Esports from "@/components/Esports";
import Photography from "@/components/Photography";
import Production from "@/components/Production";
import Lyceum from "@/components/Lyceum";
import University from "@/components/University";
import Projects from "@/components/Projects";
import Design from "@/components/Design";
import VKProjects from "@/components/VKProjects";
import WorldSkills from "@/components/WorldSkills";
import About from "@/components/About";
import Achievements from "@/components/Achievements";
import Anime from "@/components/Anime";
import Redirect from "@/components/Redirect";
import NotFound from "@/components/NotFound";

Vue.config.productionTip = false
Vue.use(VueRouter);

const routes = [
    {path: '/', component: Home},
    {path: '/about', component: About},
    {path: '/programming', component: Programming, meta: {title: "Программирование"}},
    {path: '/esports', component: Esports, meta: {title: "Киберспорт"}},
    {path: '/photography', component: Photography, meta: {title: "Фотография"}},
    {path: '/production', component: Production, meta: {title: "Продакшен"}},
    {path: '/lyceum', component: Lyceum, meta: {title: "Лицей"}},
    {path: '/university', component: University, meta: {title: "Университет"}},
    {path: '/projects', component: Projects, meta: {title: "Проекты"}},
    {path: '/design', component: Design, meta: {title: "Дизайн"}},
    {path: '/vk', component: VKProjects, meta: {title: "Проекты ВКонтакте"}},
    {path: '/worldskills', component: WorldSkills, meta: {title: "WorldSkills"}},
    {path: '/achievements', component: Achievements, meta: {title: "Достижения"}},
    {path: '/anime', component: Anime, meta: {title: "Аниме"}},
    {path: '/redirect/:id', component: Redirect, meta: {title: "Перенаправление"}, name: 'redir'},
    {path: '/*', component: NotFound, meta: {title: "404 Не найдено"}, name: 'nf'},
]

const router = new VueRouter({routes})
router.afterEach((to) => {
    window.document.title = "Иван Петров" + (to.meta.title ? " | " + to.meta.title : "")
})
new Vue({render: h => h(App), router}).$mount('#app')
