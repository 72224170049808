<template>
  <main class="w-full h-screen">
    <div class="container mx-auto flex" style="min-height: 100%;">
      <Menu class="w-full my-6"/>
<!--      <div class="w-1/3 flex-initial items-end justify-end hidden xl:flex">
        <img src="../assets/header.png" alt="Ivan Petrov" style="width: auto; max-height: 870px;">
      </div>-->
    </div>
    <Footer/>
  </main>
</template>

<script>
import Menu from './Menu';
import Footer from "@/components/Footer";

export default {
  components: {
    Menu, Footer
  }
}
</script>
