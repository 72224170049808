<template>
  <InformationPage name="Продакшен">
    Мини-проект: <a href="https://www.youtube.com/playlist?list=PLVHtUSe7-R5vCcxUMu1TtqkZYRnSBT3-L" target="_blank"
                    class="text-link">короткометражка о Легенде George Borsch</a>. <br> <br>Организация ежегодного
    лицейского мероприятия &laquo;Посвящение в лицеисты&raquo; и Всероссийского форума высоких технологий &laquo;kazanforum.doc&raquo;
    на протяжении пяти лет. О них вы можете почитать в
    <router-link to="/lyceum" class="text-link">разделе</router-link> о лицее. <br><br><a href="https://youtube.com/playlist?list=PLVHtUSe7-R5v-gkeM9tLVAPCehE-h2aOe" class="text-link"
                 target="_blank">Видеоролики о спортивной жизни в лицее</a> для презентации перед Министерством
    образования РТ. <br><br><a href="https://youtu.be/QXXhq1KYtqw" class="text-link" target="_blank">Официальный
    видеоролик</a> и <a href="https://youtube.com/playlist?list=PLVHtUSe7-R5tk_SVORfJwLL6ChwXvWVwp" class="text-link"
                        target="_blank">запись концерта</a> Выпускного 2021.

  </InformationPage>
</template>

<script>
import InformationPage from "@/components/InformationPage";

export default {
  components: {
    InformationPage
  }
}
</script>
