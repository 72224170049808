<template>
  <main class="w-full h-screen">
    <div class="container mx-auto min-h-screen py-10 flex flex-col xl:flex-row">
      <Menu :class="'w-full xl:w-1/3' + (isBig ? ' self-start' : '')"/>
      <div
          class="w-full xl:w-2/3 flex-initial items-center justify-center text-center flex flex-col mt-10 xl:mt-0 text-lg px-2 xl:px-8 xl:pl-16"
          id="main_section">
        <div class="bg-white py-8 px-10" style="border: 3px solid #f2f2f2; border-radius: 1em;">
          <h1 class="text-4xl font-bold mb-3" v-if="name">{{ name }}</h1>
          <div class="text-left">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </main>
</template>

<script>
import Menu from './Menu';
import Footer from "@/components/Footer";

export default {
  components: {
    Menu, Footer
  },
  props: ['name', 'isBig'],
  activated() {
    if (window.screen.width < 1280)
      window.scrollTo(0, document.querySelector("#main_section").offsetTop - 10)
  },
}
</script>
