<template>
  <InformationPage>
    <img src="../assets/itlkpfu.svg" alt="IT-лицей КФУ" class="max-w-sm mx-auto mb-6">
    С 2016 по 2021 год я учился в IT-лицее КФУ. <br><br>
    Я обладаю лидерскими способностями, умею креативно мыслить, организовывать рабочие процессы и имею навыки
    ораторского искусства. Это и не только помогало мне развиваться как в Лицее, так и за его пределами. <br><br>
    С 2018 по 2021 год я являлся руководителем направления по работе со сборной WorldSkills в IT-лицее КФУ.
    <br><br> С 2016 года я активно участвовал в организации Всероссийского форума высоких технологий
    «kazanforum.doc», а с 2019 года являюсь его координатором. <br><br> С 2020 по 2021 год являлся Президентом Первого
    созыва
    органа ученического со-управления «Ассоциация лицеистов». <br><br> С 2017 года я активно выступаю на конкурсах
    и форумах различного уровня. <br><br> А с 2016 года организую традиционное мероприятие «Посвящение в
    лицеисты». <br><br>В 2017 году показывал первого робота-учителя Дмитрию Медведеву.
    Об этом событии в СМИ: <a href="https://vk.com/wall-11982368_227433" target="_blank" class="text-link">Forbes</a>,
    <a href="http://government.ru/news/27802/" target="_blank" class="text-link">сайт Правительства РФ</a>,
    <a href="https://www.1tv.ru/n/325888" target="_blank" class="text-link">Первый канал</a>,
    <a href="https://vk.com/wall242521347_714" target="_blank" class="text-link">моя страница</a>
  </InformationPage>
</template>

<script>
import InformationPage from "@/components/InformationPage";

export default {
  components: {
    InformationPage
  }
}
</script>
