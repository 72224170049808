<template>
  <div class="project">
    <div class="project-image">
      <img :src="img" :alt="title">
    </div>
    <p class="project-title" v-if="!link">{{ title }}</p>
    <a class="project-title text-link" v-if="link" :href="link">{{ title }}</a>
    <p class="project-position">{{ position }}</p>
  </div>
</template>

<script>
export default {
  props: ["img", "title", "position", "link"]
}
</script>
