<template>
  <InformationPage name="Проекты">
        <h2 class="text-2xl font-bold my-3">МФСУОО &laquo;NeoWave&raquo;</h2>
        <p>NeoWave — это система интегрированных сервисов для управления образовательной организацией, контроля
          достижений учеников/студентов. Основными сервисами является: балльно-рейтинговая система, сервис личных
          достижений (грамоты, дипломы), а также платформа, позволяющая образовательным организациям развивать стартапы
          и уже сформировавшиеся проекты. Проект имеет высокую значимость, так как направлен на повышение средней
          успеваемости школьников и студентов. Также учащиеся становятся замотивированными к участию в олимпиадах,
          чемпионатах рабочих профессий WorldSkills, что точно повысит количество специалистов в различных областях и
          положительно повлияет на экономику страны.</p>
        <h2 class="text-2xl font-bold my-3">Система электронного волонтёрства в IT-лицее КФУ</h2>
        <p>Совсем недавно я запустил систему поощрения и контроля волонтёров. Для упрощения контроля деятельности
          волонтёров я написал веб-платформу. Написана на Laravel 8. Платформа активно развивается, ведь Лицей только
          начал её использование.</p>
        <h2 class="text-2xl font-bold my-3">СПКР ГИА</h2>
        <p>«СПКР ГИА» – это удобная платформа для генерации бланков различных контрольных работ, тестов (аналогичным
          ГИА) с их последующей обработкой, автоматической генерацией результатов для тестовой части и
          полуавтоматической для части с развёрнутым ответом. Ученики могут отслеживать свои результаты в специальном
          приложении, преподаватели и администрация школ может генерировать Excel-таблицы с результатами для отчётов и
          анализ сложности заданий.
        </p>
        <h2 class="text-2xl font-bold my-3">Платформа Ассоциации лицеистов</h2>
        <p>Платформа создана для публикации и контроля документационной базы, кадрового состава, структуры Ассоциации
          лицеистов IT-лицея КФУ.</p>
  </InformationPage>
</template>

<script>
import InformationPage from "@/components/InformationPage";

export default {
  components: {
    InformationPage
  }
}
</script>
