<template>
  <InformationPage name="Аниме">
    <SecretPage />
    <a href="https://shikimori.one/Van0Jan/list/anime" class="text-link">Список просмотренных мною аниме на Шикимори</a>
  </InformationPage>
</template>

<script>
import InformationPage from "@/components/InformationPage";
import SecretPage from "@/components/SecretPage";

export default {
  components: {
    InformationPage, SecretPage
  }
}
</script>
