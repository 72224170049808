<template>
  <InformationPage name="Дизайн" is-big="1">
    С 2016 года я разрабатывал дизайны различных баннеров, постов для социальных сетей и много другого для <a
      href="https://vk.com/itlkpfu" target="_blank" class="text-link">лицея</a>.<br> Мною были созданы оформления для
    «Посвящения в лицеисты» в&nbsp;2016–2019 годах, <a target="_blank" href="https://youtu.be/QXXhq1KYtqw"
                                                      class="text-link">Выпускного&nbsp;2021</a>, Форума высоких технологий «<a
      href="https://vk.com/kazanforumdoc" target="_blank" class="text-link">kazanforum.doc</a>» в 2018–2019 годах. <br><br>
    С сентября 2021 я являюсь дизайнером <a href="https://vk.com/itlkpfu" target="_blank" class="text-link">лицея</a>.
    <h2 class="text-2xl font-bold my-3">Я работал над этими проектами</h2>
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-5">
      <ProjectComponent
          :img="require('@/assets/itlkpfu.svg')"
          title="СУНЦ IT-лицей КФУ"
          position="Оформление социальных сетей, разработка и печать полиграфической продукции"
          link="//vk.com/itlkpfu"
      />
      <ProjectComponent
          :img="require('@/assets/design/kazanforumdoc.svg')"
          title="kazanforum.doc"
          position="Оформление социальных сетей, разработка и печать полиграфической продукции"
          link="//vk.com/kazanforumdoc"
      />
      <ProjectComponent
          :img="require('@/assets/design/wsitl_old.svg')"
          title="СЦК IT-лицей КФУ"
          position="Разработка бренда"
          link="//vk.com/wsitl"
      />
      <ProjectComponent
          :img="require('@/assets/design/wsitl.svg')"
          title="СЦК IT-лицей КФУ"
          position="Разработка бренда"
          link="//vk.com/wsitl"
      />
      <ProjectComponent
          :img="require('@/assets/design/lsa.svg')"
          title="Ассоциация лицеистов"
          position="Разработка бренда, разработка и печать полиграфической продукции"
          link="//vk.com/itlassoc"
      />
      <ProjectComponent
          :img="require('@/assets/design/evolpass.svg')"
          title="Система волонтёрства"
          position="Разработка бренда, разработка и печать полиграфической продукции"
      />
      <ProjectComponent
          :img="require('@/assets/design/mak3donskiy.svg')"
          title="Mak3Donskiy"
          position="Разработка логотипа для бизнеса"
      />
      <ProjectComponent
          :img="require('@/assets/design/iisdf.svg')"
          title="ФРИИС"
          position="Разработка бренда"
          link="//vk.com/iisdf"
      />
      <ProjectComponent
          :img="require('@/assets/design/tristar.svg')"
          title="TriStar Esports"
          position="Разработка бренда"
          link="//vk.com/tristar_esports"
      />
      <ProjectComponent
          :img="require('@/assets/design/pl_2017.svg')"
          title="Посвящение в лицеисты 2017"
          position="Разработка дизайна для мероприятия и его цифровой продукции, разработка и печать полиграфической продукции"
      />
      <ProjectComponent
          :img="require('@/assets/design/pl_2018.svg')"
          title="Посвящение в лицеисты 2018"
          position="Разработка дизайна для мероприятия и его цифровой продукции, разработка и печать полиграфической продукции"
      />
      <ProjectComponent
          :img="require('@/assets/design/pl_2019.svg')"
          title="Посвящение в лицеисты 2019"
          position="Разработка дизайна для мероприятия и его цифровой продукции, разработка и печать полиграфической продукции"
      />
      <ProjectComponent
          :img="require('@/assets/design/pl_2020.svg')"
          title="Посвящение в лицеисты 2020"
          position="Разработка дизайна для мероприятия и его цифровой продукции, разработка и печать полиграфической продукции"
      />
      <ProjectComponent
          img="https://upload.wikimedia.org/wikipedia/ru/b/be/%D0%9B%D0%BE%D0%B3%D0%BE%D1%82%D0%B8%D0%BF_%D0%9F%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D0%B0_%D0%9A%D0%B8%D0%B1%D0%B5%D1%80%D1%81%D0%BF%D0%BE%D1%80%D1%82.svg"
          title="Проект Киберспорт на Википедии"
          position="Разработка логотипа"
      />
    </div>
  </InformationPage>
</template>

<script>
import InformationPage from "@/components/InformationPage";
import ProjectComponent from "@/components/ProjectComponent";

export default {
  components: {
    InformationPage, ProjectComponent
  }
}
</script>
