<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <keep-alive>
        <router-view/>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    updateLinks() {
      const links = document.querySelectorAll("a")
      for (const link of links) {
        if (location.hostname !== link.hostname) {
          link.setAttribute("target", "_blank")
          link.setAttribute("rel", "nofollow noopener noreferrer")
        }
      }
    }
  },
  updated() {
    this.updateLinks()
  },
  mounted() {
    this.updateLinks()
  }
}
</script>
