<template>
  <InformationPage>
    <img src="../assets/leti.svg" alt="ЛЭТИ" class="max-w-sm mx-auto mb-6">
    В 2021 году я поступил в СПбГЭТУ «ЛЭТИ».<br><br>
    С февраля 2022 года я устроился на работу PHP-разработчиком в Отдел информационных систем моего университета СПбГЭТУ «ЛЭТИ». Разрабатываю систему личного кабинета, помогаю в нескольких других проектах <br><br>
    С недавнего времени я начал вести бесплатные курсы по PHP-фреймворку Laravel в качестве преподавателя студенческого университета IT-ЛЭТИ. Я давно хотел начать делиться своим опытом с миром, поэтому совсем скоро я запущу дополнительный курс на своём YouTube-канале.
  </InformationPage>
</template>

<script>
import InformationPage from "@/components/InformationPage";

export default {
  components: {
    InformationPage
  }
}
</script>
