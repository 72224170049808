<template>
  <InformationPage name="Разработка">
    <p>
      В ЛЭТИ основной стэк &mdash; PHP + Laravel и Go. <br> Frontend пишу преимущественно на ReactJS, могу использовать VueJS.<br><br>
      Для своих задач использую языки сверху, а также: C++, C#, Python и Java. <br><br>
      Чуть больше вы можете узнать в моём <a href="//github.com/lazytechwork" target="_blank" class="text-link">GitHub</a>. <br><br>
    </p>
    <h2 class="text-2xl font-bold my-3 text-center">Если коротко &hellip;</h2>
    <table class="rating center-block">
      <tr>
        <td colspan="2" class="text-center">Языки программирования</td>
        <td colspan="2" class="text-center">Фреймворки</td>
      </tr>
      <tr>
        <th>PHP</th>
        <td>
          <Stars value="5"/>
        </td>
        <th>Laravel</th>
        <td>
          <Stars value="5"/>
        </td>
      </tr>
      <tr>
        <th>JS</th>
        <td>
          <Stars value="5"/>
        </td>
        <th>ReactJS</th>
        <td>
          <Stars value="5"/>
        </td>
      </tr>
      <tr>
        <th>Java</th>
        <td>
          <Stars value="4"/>
        </td>
        <th>VueJS</th>
        <td>
          <Stars value="4"/>
        </td>
      </tr>
      <tr>
        <th>C++</th>
        <td>
          <Stars value="4"/>
        </td>
        <th>Forge</th>
        <td>
          <Stars value="3"/>
        </td>
      </tr>
      <tr>
        <th>Go</th>
        <td>
          <Stars value="2"/>
        </td>
      </tr>
    </table>
  </InformationPage>
</template>

<script>
import InformationPage from "@/components/InformationPage";
import Stars from "@/components/Stars";

export default {
  components: {
    InformationPage, Stars
  }
}
</script>
