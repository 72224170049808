<template>
  <InformationPage name="Киберспорт">
    Я играю в CS:GO, а также проводил и планирую проводить в дальнейшем киберспортивные чемпионаты по
    Counter-Strike: Global Offensive среди образовательных организаций под брендом <a
      href="//vk.com/tristar_esports" target="_blank" class="text-link">TriStar Esports</a>.<br><br>

    С 2021 года я активно начал заниматься редактированием и созданием статей в
    <a href="https://ru.wikipedia.org/wiki/User:Ivan_Petrov" target="_blank" class="text-link">Википедии</a>.
    Преимущественно это статьи о киберспортивной дисциплине Counter-Strike:&nbsp;Global&nbsp;Offensive (в частности это
    статьи о командах и игроках Natus&nbsp;Vincere, Gambit, Team&nbsp;Spirit, а также освещение последних двух
    мейджоров: в Стокгольме и Антверпене). Один из координаторов вики-проекта «Киберспорт».
  </InformationPage>
</template>

<script>
import InformationPage from "@/components/InformationPage";

export default {
  components: {
    InformationPage
  }
}
</script>
