<template>
  <InformationPage name="Достижения">
    <div class="text-left">
      <AchievementCell medal="1" year="2017">Региональный чемпионат в РТ</AchievementCell>
      <AchievementCell medal="2" year="2017">Проектная олимпиада &laquo;Генеральный конструктор&raquo;</AchievementCell>
      <AchievementCell year="2018">&laquo;Digital Start&raquo;</AchievementCell>
      <AchievementCell year="2018">Венчурный акселератор</AchievementCell>
      <AchievementCell medal="1" year="2018">Региональный чемпионат в РТ</AchievementCell>
      <AchievementCell year="2018">Сертификация Microsoft Office Specialist</AchievementCell>
      <AchievementCell medal="3" year="2019">&laquo;Большие вызовы&raquo;</AchievementCell>
      <AchievementCell medal="2" year="2019">Финал VII Национального чемпионата</AchievementCell>
      <AchievementCell medal="2" year="2019">VI Всероссийский форум высоких технологий «kazanforum.doc»</AchievementCell>
      <AchievementCell medal="1" year="2019">&laquo;Цифровой прорыв&raquo; (школьный трек)</AchievementCell>
      <AchievementCell medal="1" year="2019">Региональный чемпионат в РТ</AchievementCell>
      <AchievementCell medal="2" year="2020">&laquo;Большие вызовы&raquo;</AchievementCell>
      <AchievementCell medal="3" year="2020">Научная конференция им. Н.И.Лобачевского</AchievementCell>
      <AchievementCell year="2020">Онлайн-стажировка Иннополиса</AchievementCell>
      <AchievementCell year="2020">&laquo;Цифровой прорыв&raquo; (основной трек)</AchievementCell>
      <AchievementCell year="2020">Российско-индийская проектная образовательная программа Сириус</AchievementCell>
    </div>
  </InformationPage>
</template>

<script>
import InformationPage from "@/components/InformationPage";
import AchievementCell from "@/components/AchievementCell";

export default {
  components: {
    InformationPage, AchievementCell
  }
}
</script>
