<template>
  <div class="rounded-xl px-3 py-4 text-white text-center shadow my-5" style="background: #dc343b;">
    {{ !hasText ? 'Вы попали на скрытую страницу' : '' }}
    <slot/>
  </div>
</template>

<script>
export default {
  props: ['hasText']
}
</script>
