<template>
  <div style="display: inline-block;" class="text-brand">
    <span v-for="i in parseInt(value)" :key="'s'+i">★</span><span v-for="i in (5-value)" :key="'u'+i">☆</span>
  </div>
</template>

<script>
export default {
  name: "Stars",
  props: ["value"]
}
</script>