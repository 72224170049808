<template>
  <InformationPage name="WorldSkills">
        <p>В 2017 году я впервые поучаствовал на Региональном чемпионате WorldSkills в компетенции «Веб-дизайн и
          разработка» и выиграл. Я отправился в Москву на Отборочные соревнования. К сожалению, я попал в четвёрку
          лучших, а проходило всего три человека. Мой путь к Финалу на этом не закончился. На следующем Региональном
          чемпионате в 2018 году я познакомился с моим будущим тренером, с которым я и дошёл до Финала в 2019 году. В
          итоге в моей копилке чемпионатов WorldSkills оказались 3 золотые медали Регионального чемпионата Республики
          Татарстан (2 раза как Юниор, 1 раз в основной возрастной группе), а также серебряная медаль Финала
          Национального чемпионата WorldSkills 2019 в городе Казани.</p>

        <h2 class="text-2xl font-bold my-3">Если коротко &hellip;</h2>
        <div class="text-left">
          <p class="my-1"><img src="https://upload.wikimedia.org/wikipedia/commons/4/47/Gold_medal_icon.svg" alt="Золотая медаль" class="h-5 inline"> Региональный чемпионат в РТ 2017 (юниор)</p>
          <p class="my-1"><img src="https://upload.wikimedia.org/wikipedia/commons/4/47/Gold_medal_icon.svg" alt="Золотая медаль" class="h-5 inline"> Региональный чемпионат в РТ 2018 (юниор)</p>
          <p class="my-1"><img src="https://upload.wikimedia.org/wikipedia/commons/2/2e/Silver_medal_icon.svg" alt="Серебряная медаль" class="h-5 inline"> Финал VII Национального чемпионата 2019 (Сборная РТ, юниор)</p>
          <p class="my-1"><img src="https://upload.wikimedia.org/wikipedia/commons/4/47/Gold_medal_icon.svg" alt="Золотая медаль" class="h-5 inline"> Региональный чемпионат в РТ 2019 (основная категория)</p>
        </div>

        <h2 class="text-2xl font-bold my-3">Упоминания в СМИ &hellip;</h2>
        <ul>
          <li><a target="_blank" href="https://youtu.be/M8TAiIN-7jw?t=5032" class="text-link">Момент награждения на Церемонии закрытия Нацфинала 2019</a></li>
          <li><a target="_blank" href="https://vk.com/wall-139967779_1628" class="text-link">Интервью для WorldSkills в РТ</a></li>
          <li><a target="_blank" href="https://youtu.be/YCzZGm22n_4" class="text-link">Интервью для телеканала Россия 1</a></li>
        </ul>
        <h2 class="text-2xl font-bold my-3">Также &hellip;</h2>
        <p class="italic">Хочу поблагодарить за все победы моего наставника <a href="//vk.com/id18661439" target="_blank" class="text-link">Вадима Сергеевича Гуськова</a>, тренера
          <a href="//vk.com/id82186681" target="_blank" class="text-link">Руслана Алексеевича Вальшина</a>, а также свою маму и семью.</p>

  </InformationPage>
</template>

<script>
import InformationPage from "@/components/InformationPage";

export default {
  components: {
    InformationPage
  }
}
</script>
