<template>
  <NotFound/>
</template>

<script>
import NotFound from "@/components/NotFound";

const REDIRECTS = {
  'ani': 'https://shikimori.one/Van0Jan/list/anime'
}

export default {
  components: {NotFound},
  mounted() {
    if (this.$route.params.id in REDIRECTS) {
      const a = document.createElement("a")
      a.href = REDIRECTS[this.$route.params.id]
      a.target = "_blank"
      a.rel = "nofollow noopener noreferrer"
      const link = document.body.appendChild(a)
      link.click()
      this.$router.push('/')
    }
  }
}
</script>