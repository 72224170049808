<template>
  <InformationPage name="Фотография">
    У меня есть зеркалка Canon EOS 600D и ширик TAMRON 18-200mm, иногда я снимаю всякое и выкладываю обработанные фотки. Ознакомиться со снимками
    можно в <a href="//instagram.com/irupetrov" target="_blank" class="text-link">моём втором инстаграме</a>.
  </InformationPage>
</template>

<script>
import InformationPage from "@/components/InformationPage";

export default {
  components: {
    InformationPage
  }
}
</script>
