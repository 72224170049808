<template>
  <InformationPage name="Обо мне">
    <div class="grid grid-cols-1 xl:grid-cols-3 gap-8">
      <img
          class="w-full rounded-2xl max-w-md hidden xl:block"
          src="https://sun9-69.userapi.com/s/v1/ig2/M2aeu-RKG3uqiFyo19tXe8hITx3CMI3wql0rUkJzE6gn4ctAF-PRH-b1BYeOz_tJsnsjAup5V5i1heQip0UwvG8z.jpg?size=400x597&quality=95&crop=852,196,1010,1509&ava=1"
          alt="Ivan Petrov">
      <img
          class="w-32 rounded-full mx-auto xl:hidden"
          src="https://sun9-69.userapi.com/s/v1/ig2/qzMws2lzBwQNyhYcaruJ2KKptdOcbeaftVbTnPAe1CXDhTDryNY_zTTrULYO1NNR_MYqscrU3rDSYtNZxRaOvFmA.jpg?size=200x200&quality=95&crop=1072,252,528,528&ava=1"
          alt="Ivan Petrov">
      <div class="xl:col-span-2">
        Полное имя: <b>Петров Иван Рюрикович</b><br>
        Дата рождения: <b>04.07.2003 ({{ age }} {{ ageNumeral(age) }})</b><br>
        Школа:
        <ul class="list-decimal list-inside">
          <li>2010&ndash;2016 &mdash; <a href="//vk.com/g32nk" target="_blank" class="text-link">Гимназия №32</a> (г.
            Нижнекамск)
          </li>
          <li>2016&ndash;2021 &mdash; <a href="//vk.com/itlkpfu" target="_blank" class="text-link">СУНЦ IT-лицей КФУ</a>
            (г.
            Казань)
          </li>
        </ul>

        Университет:
        <ul class="list-decimal list-inside">
          <li>2021&ndash;2025 &mdash; <a href="//vk.com/spbsetu" target="_blank" class="text-link">СПбГЭТУ «ЛЭТИ»</a> (бакалавриат)
          </li>
        </ul>
        Статус: <b>PHP-разработчик в <a href="//vk.com/spbsetu" target="_blank" class="text-link">СПбГЭТУ «ЛЭТИ»</a></b><br>
        Хобби: <b>Дизайн, видеомонтаж, киберспорт</b><br>
        Уровень английского: <b>C1 (Advanced)</b><br>
      </div>
    </div>
  </InformationPage>
</template>

<script>
import InformationPage from "@/components/InformationPage";

export default {
  components: {
    InformationPage
  },
  computed: {
    age: () => Math.abs(new Date(Date.now() - new Date("2003-07-04T10:35:00+03:00").getTime()).getUTCFullYear() - 1970),
  },
  methods: {
    ageNumeral: (age) => age % 10 === 1 ? "год" : (age % 10 >= 2 && age % 10 < 5 ? "года" : "лет")
  }
}
</script>
